import { Container, Row, Col } from 'react-bootstrap';

function About() {
  return (
    <div style={{margin:"30px", backgroundColor: "#fff"}}>
<Container id="about" fluid >
      <Row className="align-items-center">
        <Col
          xs={12}
          sm={6}
          md={6}
          className="d-flex justify-content-center mb-4 mb-sm-0"
          style={{ borderRadius: "10px" }}
        >
          <img
            src="c.jpg"
            className="img-fluid"
            alt="JTL Tower Installation"
            style={{
              borderRadius: "5px",
              maxHeight: "100%", // Keep image responsive on all screen sizes
              objectFit: "cover" // To ensure the image doesn't get distorted
            }}
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          md={6}
          className="text-center text-sm-start"
        >
          <p>
            GTL Tower Installation offers expert services for communication
            tower installation.
          </p>
          <p>
          GTL Tower collaborates with a highly skilled team to ensure 
          seamless and hassle-free tower installation services. 
          Our primary offerings include Tower Installation and
           Mobile Tower Installation. We provide specialized solutions for various types of tower
           installations, such as Roof
            Tower Installation, Plot Tower
             Installation, and Mobile Tower Installation.
          </p>
          <p>
          Leading companies such as Hindustan Towers Group, GSTL Tower Ltd.,
            GTL Infrastructure Ltd., and MG Towers Private Limited offer
            comprehensive tower installation services across India. Customers
            frequently search for support with Jio Tower Helpline, Jio Tower
            Installation Contact Number, Airtel Tower Helpline, and Airtel Tower
            Installation Contact Number.
          </p>
        </Col>
      </Row>
    </Container>
    </div>
    
  );
}

export default About;
