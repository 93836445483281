import React from "react";

const ContactForm = () => {
  return (
    <div 
      style={{
        maxWidth: "500px", 
        margin: "0 auto", 
        padding: "20px", 
        fontFamily: "Arial, sans-serif", 
        border: "1px solid #ccc", 
        borderRadius: "8px", 
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#87CEEB" // Light sky blue background
      }}
    >
      <h2 style={{ textAlign: "center", color: "#333", }}>Apply For Mobile Tower Installation </h2>
      <form
        action="https://formspree.io/f/xpwzojva" // Replace with your Formspree endpoint
        method="POST"
      >
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Name:</label>
          <input type="text" placeholder="Enter Your Name"  name="name" required style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }} />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Mobile Number:</label>
          <input type="text" placeholder="Enter Mobile Number" name="mobile" required style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }} />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Email ID:</label>
          <input type="email" placeholder="Enter Your Email Id" name="email" required style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }} />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Rural/Urban:</label>
          <input type="text" placeholder="Rural/Urban" name="village" required style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }} />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>City/Village:</label>
          <input type="text" placeholder="Enter Your City/Village Name" name="village" required style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }} />
        </div>
        
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>District:</label>
          <input type="text" placeholder="Enter Your District Name" name="city" required style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }} />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>State:</label>
          <input type="text" placeholder="Enter Your State Name" name="state" required style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }} />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Pincode:</label>
          <input type="text" placeholder="Enter Your Pin Code" name="pincode" required style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }} />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Company:</label>
          <input type="text" name="company" placeholder="Jio, Airtel, VI, BSNL" required style={{ width: "100%", padding: "8px", border: "1px solid #ccc", borderRadius: "4px" }} />
        </div>
        <button 
          type="submit" 
          style={{
            width: "100%", 
            padding: "10px", 
            backgroundColor: "#4CAF50", 
            color: "#fff", 
            border: "none", 
            borderRadius: "4px", 
            cursor: "pointer", 
            fontWeight: "bold"
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactForm;