import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const ATCGroup = () => {
  return (
    <Container>
      <Row>
        {/* First Div - First part of content */}
        <Col sm={12} md={6} className="d-flex">
          <Card className="mb-4 flex-fill">
            <Card.Body>
              <h5>GTL Tower Group</h5>
              <p>
                GTL Tower Group provides you with communication tower installation services. 
                GTL Tower Group works with well-experienced staff to provide you hassle-free 
                communication tower services. Our main services are tower installation service, 
                tower maintenance service, and tower repairing service. Mobile tower services are 
                also available. If you want to know about details like - mobile tower installation 
                service, tower installation service on roof, tower installation service on land, 4G and 5G tower installation service.
              </p>
            </Card.Body>
          </Card>
        </Col>

        {/* Second Div - Second part of content */}
        <Col sm={12} md={6} className="d-flex">
          <Card className="mb-4 flex-fill">
            <Card.Body>
              <h5>Comprehensive Solutions</h5>
              <p>
                GTL Tower Group is a premier provider of comprehensive 
                solutions in mobile tower installation services. Our
                 dedicated team excels in every aspect, from site selection and 
                 regulatory approvals to advanced technical planning and tower erection.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ATCGroup;
