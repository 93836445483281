import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function ColorSchemesExample() {
  return (
    <>
      <Navbar expand="lg" style={{ backgroundColor: "#6CB4EE", color: "black" }} data-bs-theme="dark">
        <Container>
          {/* Navbar Brand with logo */}
          <Navbar.Brand href="#home">
            <img 
              src={`${process.env.PUBLIC_URL}/Gtl3.jpg`} 
              alt="Logo" 
              style={{ height: '40px', width: 'auto' }} 
            />
          </Navbar.Brand>

          {/* Hamburger menu toggle button for mobile screens */}
          <Navbar.Toggle aria-controls="navbar-nav" />

          {/* Navbar links */}
          <Navbar.Collapse id="navbar-nav">
            <Nav className="ms-auto" style={{ color: "black" }}>
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#about ">About Us</Nav.Link>
              <Nav.Link href="#service">Services</Nav.Link>
              <Nav.Link href="#contact">Contact Us</Nav.Link>
              <Nav.Link href="#contact">Land Owners</Nav.Link>
              <Nav.Link href="#contact">Works</Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorSchemesExample;
