import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function LandOver() {
  return (
    <Container>
        <Row className="d-flex justify-content-center align-items-center m-5">
            {/* Left Column */}
            <Col 
                md={5} 
                xs={12} // 12 columns on small screens (full width)
                className="mb-4 mb-md-0"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "20px", // Padding for inner spacing
                    border: "1px solid #ccc", // Border around each column
                    borderRadius: "10px", // Rounded corners
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Shadow effect
                    backgroundColor: "orange", // White background
                    margin: "10px" // Margin to separate columns
                }}
            >
                <h3>Land Requirements</h3>
                <h6>* LAND 20X20=400 SQFT</h6>
                <h6>* Land Papers</h6>
                <h6>* ID Proof : Pan Card Aadhar Card</h6>
                <h6>* Residence Proof (Aadhar Card or Voter ID Card)</h6>
                <h6>* 1 Passport Size Photo</h6>
            </Col>

            {/* Right Column */}
            <Col 
                md={5} // 5 columns on medium and larger screens
                xs={12} // 12 columns on small screens (full width)
                className="mb-4 mb-md-0"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "20px", // Padding for inner spacing
                    border: "1px solid #ccc", // Border around each column
                    borderRadius: "10px", // Rounded corners
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Shadow effect
                    backgroundColor: "orange", // White background
                    margin: "10px" // Margin to separate columns
                }}
            >
                <h3>Our Facilities</h3>
                <h6>* Advance: ₹60,00,000 - ₹90,00,000</h6>
                <h6>* Rent: ₹45,000 Monthly</h6>
                <h6>* 20 Year Court Agreement</h6>
                <h6>* Solar Panel</h6>
                <h6>* 01 Security Guard Jobs: ₹18,000 - ₹20,000</h6>
            </Col>
        </Row>
    </Container>
  );
}

export default LandOver;
