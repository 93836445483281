
import Header from"./Header"
import Crousel from "./Crousel"

import About from './About';
import Services from './Services';
import LandOver from "./LandOver";
import Process from "./Process";
import StatsSection from "./StatsSection";
import ContactSection from "./ContactSection";
import AssociatedWithUs from "./AssociatedWithUs";
import ContactButtons from "./ContactButtons";
import Heading from "./Heading";
import MarqueeComponent from "./MarqueeComponent";
import ATCGroup from "./ATCGroup";


import ContactForm from "./ContactForm";
import Landowner from "./Land";









function App() {
  return (
    <div style={{ 
      overflow:"hidden"
    }}>
      <Heading/>
      <Header/>
      <Crousel/>
      <MarqueeComponent/>
      
      <ContactForm/>
      <About/>
      <AssociatedWithUs/>
      <ATCGroup/>

      
 <Services/>
<LandOver/>
<Process/>
<StatsSection/>
<Landowner/>
<ContactSection/>
<ContactButtons/>



    </div>
  );
}

export default App;
