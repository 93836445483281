import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const ContactSection = () => {
  return (
    <div id="contact" style={{ backgroundColor: "#808080", color: "#ffffff" }}>
      {/* Hero Section */}
      <Container fluid className="text-center py-5" style={{ backgroundColor: "#fff", color: "#000" }}>
        <h1 className="fw-bold" style={{ color: "#000" }}>Connecting Dreams, One Call Away.</h1>
        <p className="mt-3" style={{ color: "#666", fontSize: "18px" }}>
          Amplify your connectivity journey with us. Reach out for proactive solutions and
          redefine connectivity together. Experience unparalleled service that fulfills expectations.
        </p>
        <Button
          href="#"
          style={{ backgroundColor: "#0000FF", border: "none", padding: "10px 20px" }}
          className="mt-3"
        >
          Contact Us ↗
        </Button>
      </Container>

      {/* About Us Section */}
      <Container className="py-5">
        <Row>
          <Col lg={12} className="mb-4">
            <h4>About Us</h4>
            <p style={{ color: "#fff", fontSize: "16px" }}>
              Our tower installation service is the best. For all wireless telecommunications networks, 
              we are the top tower installation company. We have experience and are performing well in business.
            </p>
          </Col>
        </Row>

        {/* Links Section */}
        <Row>
          {/* Quick Links */}
          <Col lg={4} md={6} className="mb-4">
            <h5>Quick Links</h5>
            <ul style={{ listStyleType: "none", padding: 0, color: "#fff", fontSize: "16px" }}>
              <li><a href="#home" style={{ color: "#fff" }}>Home</a></li>
              <li><a href="#home" style={{ color: "#fff" }}>About Us</a></li>
              <li><a href="#service" style={{ color: "#fff" }}>Our Services</a></li>
              <li><a href="#contact" style={{ color: "#fff" }}>Contact Us</a></li>
            </ul>
          </Col>

          {/* Other Links */}
          <Col lg={4} md={6} className="mb-4">
            <h5>Other Links</h5>
            <ul style={{ listStyleType: "none", padding: 0, color: "#fff", fontSize: "16px" }}>
              <li>Mobile Tower Installation</li>
              <li>Towers Installation</li>
              <li>Mobile Network Solution</li>
              <li>4g & 5g Tower Installation</li>
            </ul>
          </Col>

          {/* Contact Information */}
          <Col lg={4} md={12}>
            <h5>Information</h5>
            <ul style={{ listStyleType: "none", padding: 0, color: "#fff", fontSize: "16px" }}>
              <li>📞 +91749784505</li>
              <li>📧 gtlinfrastructuregovt@gmail.com</li>
              <li>
                📍 GTL Infrastructure Limited
                “Global Vision”, 3rd Floor, Electronic Sadan-II, MIDC, TTC Industrial Area, Mahape,
                Navi Mumbai - 400710, Maharashtra, India.
              </li>
              <li>CIN - L74210MH2004PLC144367</li>
            </ul>
          </Col>
        </Row>

        {/* Google Map Section */}
        <Row className="mt-5">
          <Col>
            <h5>Our Location</h5>
            {/* Google Map Embed */}
            <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.3121312464716!2d73.0223852152358!3d19.10499124482856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b64de8db95ff%3A0x706728ec89b57b25!2sGTL%20Infrastructure%20Limited!5e0!3m2!1sen!2sin!4v1617772840970!5m2!1sen!2sin"
  width="100%"
  height="400"
  style={{ border: 0 }}
  allowFullScreen=""
  aria-hidden="false"
  tabIndex="0"
/>

          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactSection;
