import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Process() {
  return (
    <Container style={{

        backgroundColor: "#f1f1f1", 
            borderRadius: "10px",  // Rounded corners for the container
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Light shadow for depth
            marginBottom: "30px"
    }}>
        <div style={{ textAlign: "center", margin: "auto", padding: "20px"}}>
            <h2>Installation Process</h2>
            <div>
                We have a streamlined installation process that ensures 
                a hassle-free experience. Expert technicians use cutting-edge 
                technology, guaranteeing a swift and efficient setup for your connectivity needs.
            </div>
        </div>
        
        {/* First Row */}
        <Row>
            <Col xs={12} md={6} className="p-3">
                <div style={{
                    padding: "20px",
                    borderRadius: "8px",
                    border: "1px solid #ddd",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#f9f9f9"
                }}>
                    <h4>Space Required For Installation </h4>
                    Efficiency meets minimalism. 
                    Our installations are designed
                     to optimize space usage. We 
                     ensuring a clean blend with 
                     your surroundings while delivering 
                     robust connectivity.
                </div>
            </Col>
            <Col xs={12} md={6} className="p-3">
                <div style={{
                    padding: "20px",
                    borderRadius: "8px",
                    border: "1px solid #ddd",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#f9f9f9"
                }}>
                   <h4> Documentation Required</h4>
                    We simplify the paperwork.
                    We guide you through the 
                    required documentation,
                    making the process smooth 
                    and transparent. Our team 
                    ensures compliance and a swift installation.
                </div>
            </Col>
        </Row>

        {/* Second Row */}
        <Row>
            <Col xs={12} md={6} className="p-3">
                <div style={{
                    padding: "20px",
                    borderRadius: "8px",
                    border: "1px solid #ddd",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#f9f9f9"
                }}>
                   <h4> Application Approval</h4>
                    Navigating approvals
                    with ease. Our team
                    assists in securing 
                    necessary approvals.
                    We expedite the process and
                    ensure a timely commencement of your installation.
                </div>
            </Col>
            <Col xs={12} md={6} className="p-3">
                <div style={{
                    padding: "20px",
                    borderRadius: "8px",
                    border: "1px solid #ddd",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#f9f9f9"
                }}>
                   <h4>Installation Process</h4>
                   Step into the future
                   of connectivity. Our
                    installation process is more than 
                    a task. It's a journey toward a more connected future.
                     Trust us to make it efficient and effective.
                </div>
            </Col>
        </Row>
    </Container>
  );
}

export default Process;
