import React from 'react';
import { Button } from 'react-bootstrap';
import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa'; // Importing the icons from react-icons
import './ContactButtons.css'; // Import the CSS file for styles

function ContactButtons() {
  return (
    <div>
      {/* Phone Button */}
      <Button
        href="tel:+917497845054" // Replace with your phone number
        target="_blank"
        className="contact-button phone-button"
      >
        <FaPhoneAlt size={24} />
      </Button>

      {/* WhatsApp Button */}
      <Button
        href="https://wa.me/+917497845054" // Replace with your WhatsApp number
        target="_blank"
        className="contact-button whatsapp-button"
        style={{backgroundColor:"green"}}
      >
        <FaWhatsapp size={24} />
      </Button>
    </div>
  );
}

export default ContactButtons;
