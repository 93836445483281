import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const Landowner = () => {
  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4">Landowner</h2>
      <Row>
        <Col md={6}>
          {/* Replace "your-image-path.jpg" with an actual image source */}
          <Image 
            src="card2.jpg" 
            alt="Service Image" 
            fluid 
            rounded 
          />
        </Col>
        <Col md={6}>
          <p style={{fontSize:"18px"}}>
            Through our company-driven involvement programs, we offer landowners in all circles the best service available. Among these are health examination camps, Coordinating Swachh Bharat Abhiyan neighborhood clean-ups, visiting NGOs and schools, planting trees, managing pests, and so on.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Landowner;
