import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const StatsSection = () => {
  // Stats data
  const stats = [
    { value: 20, label: "Years of Experience" },
    { value: 26000, label: "Finished Projects" },
    { value: 25750, label: "Satisfied Happy Clients" },
  ];

  // State for each stat to store the incremented value
  const [currentStats, setCurrentStats] = useState([
    { value: 0, label: "Years of Experience" },
    { value: 0, label: "Finished Projects" },
    { value: 0, label: "Satisfied Happy Clients" },
  ]);

  const countersRef = useRef([0, 0, 0]); // Keep track of current counts without triggering re-renders
  const sectionRef = useRef(null); // Reference for the section to observe

  // Effect hook to handle the counting animation
  useEffect(() => {
    const counters = [20, 26000, 25750]; // Target values for stats
    const duration = 2000; // Duration for counting animation (in ms)
    const interval = 50; // Interval between each increment (in ms)

    const updateCounter = (index) => {
      let counter = 0;
      const step = Math.ceil(counters[index] / (duration / interval)); // Increment step

      const counterInterval = setInterval(() => {
        counter += step;
        if (counter >= counters[index]) {
          clearInterval(counterInterval); // Stop when reaching the target value
          counter = counters[index]; // Set to exact target value
        }

        // Update the state to trigger re-render
        setCurrentStats((prevStats) => {
          const newStats = [...prevStats];
          newStats[index] = { ...newStats[index], value: counter };
          return newStats;
        });
      }, interval);
    };

    // IntersectionObserver to trigger the counting animation when the section is in view
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            stats.forEach((stat, index) => {
              updateCounter(index); // Start animation when section comes into view
            });
            observer.disconnect(); // Stop observing after animation starts
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the section is in view
    );

    // Observe the stats section
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Cleanup function
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array to run the effect once on component mount

  return (
    <Container
      fluid
      style={{ backgroundColor: "#F0F8FF", color: "#fff", padding: "50px 0" }}
      ref={sectionRef} // Attach the ref to the section we want to observe
    >
      <Row className="text-center">
        {currentStats.map((stat, index) => (
          <Col key={index} sm={12} md={4} className="mb-4">
            <Card className="border-0 bg-transparent">
              <Card.Body>
                <h1>{stat.value}+</h1>
                <p>{stats[index].label}</p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default StatsSection;
 