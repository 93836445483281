import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

function AssociatedWithUs() {
  return (
    <Container fluid className="bg-white py-4" style={{backgroundColor: "#fff"}}>
      <h1 className="text-center">Associated With <img src='Gtl.jpg' style={{height:"50px"}}/></h1>
      <br />
      <br />
      <Row className="justify-content-center">
        <Col xs="auto">
          <Image src="airtell.png" alt="Airtel" className="associated-logos" style={{height:"150px"}} />
        </Col>
        <Col xs="auto">
          <Image src="bsnll.png" alt="BSNL" className="associated-logos" />
        </Col>
        <Col xs="auto">
          <Image src="jio.png" alt="Jio" className="associated-logos" style={{height:"150px",marginTop:"18px" }} />
        </Col>
        <Col xs="auto">
          <Image src="vii.png" alt="Idea" className="associated-logos" style={{height:"150px", marginTop:"28px"}}/>
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  );
}

export default AssociatedWithUs;
