import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Heading = () => {
  return (
    <div className="bg-primary text-white py-2">
      <div className="container">
        <div className="row d-flex justify-content-between align-items-center" style={{ fontSize: "10px" }}>
          
          {/* Left side: Phone Number with border */}
          <div className="col-4 text-start">
            <div className="border border-light p-2">
              <span className="fs-7 mt-1" style={{ marginLeft: "2px" }}>📞 +917497845054</span>
            </div>
          </div>

          {/* Center: Registration Fee with border */}
          <div className="col-4 text-center">
            <div className="border border-light p-2">
              <span className="fs-7 mb-1">Registration Fee: ₹1250/-</span>
            </div>
          </div>

          {/* Right side: Email with border */}
          <div className="col-12 col-md-4 text-end" style={{ fontSize: "12px" }}>
  <div className="border border-light p-2" >
    <span className="fs-7 mt-1" style={{ fontSize: "16px", padding: "8px" , display:"flex", textAlign:"center", marginLeft:"12px"}}>📧 gtl@gtlinfrastructurelimited.com</span>
  </div>
</div>


          
        </div>
      </div>
    </div>
  );
};

export default Heading;
