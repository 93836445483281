import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';


function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div id='home'>
 <Carousel activeIndex={index} onSelect={handleSelect} 
    style={{
       
        height:"400PX"
    }}
    >
      <Carousel.Item>
      <img src='b.jpg'
      style={{
        height:"400px",
        width:"100%"
      }}
      />
        <Carousel.Caption>
          <h3>26,000 Towers in 22 Telecom Circles</h3>
          <p>
          Telecom Tower Company
          India's truly Independent and Neutral
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src='c.jpg'
      style={{
        height:"400px",
        width:"100%"
      }}
      />
        <Carousel.Caption>
          <h3>26,000 Towers in 22 Telecom Circles</h3>
          <p>
          Telecom Tower Company
          India's truly Independent and Neutral
        </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src='D.jpg'
      style={{
        height:"400px",
        width:"100%"
      }}
      />
        <Carousel.Caption>
          <h3>Sharing Resources</h3>
          <p>
          
          Inspiring Growth
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

    </div>
   
  );
}

export default ControlledCarousel;