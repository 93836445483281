import React from 'react';
import { Container } from 'react-bootstrap';
import './MarqueeComponent.css'; // Import the CSS file

const MarqueeComponent = () => {
  return (
    <Container className="py-2">
      <div className="marquee-container">
        <span className="marquee-text">
          
25750+ Mobile Towers have already been installed across India,
 reaping the benefits. You should also proceed quickly पूरे भारत में 25750+ टॉवर हमारे द्वारा इंस्टॉल किए गए हैं। आप भी आज ही आवेदन करें।


        </span>
      </div>
    </Container>
  );
};

export default MarqueeComponent;
