import { Container, Row, Col, Card, Button } from 'react-bootstrap';

function Services() {
  return (
    <Container fluid style={{
      marginTop: "20px",
      textAlign: "center",
      padding: "0", // Removes extra padding from container
    }}>
      <h3><img src='Gtl.jpg' style={{height:"50px"}}/> Services</h3>
      <Row style={{
        display: "flex",              // Ensures the items are in a flexbox container
        justifyContent: "center",    // Centers the items horizontally
        alignItems: "stretch",       // Ensures equal height cards
        gap: "20px",                 // Adds space between the cards
        flexWrap: "wrap",            // Ensures that the items wrap on smaller screens
      }}>
        <Col xs={12} sm={6} md={4} className="d-flex justify-content-center">
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src="card4.jpg" style={{ height: "180px", objectFit: "cover" }} />
            <Card.Body>
              <Card.Title>Mobile Tower Installation on Land
              </Card.Title>
              <Card.Text>
              Mobile Tower Installation on Land involves ground-based
               tower construction to enhance coverage in rural areas.
                GTL Tower Installation ensures reliable service, 
                scalability, and improved mobile connectivity for underserved regions.

              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={4} className="d-flex justify-content-center">
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src="card2.jpg" style={{ height: "180px", objectFit: "cover" }} />
            <Card.Body>
              <Card.Title>Mobile Tower Installation on Roof </Card.Title>
              <Card.Text>
              Mobile Tower Installation on Roof involves placing 
              towers on building rooftops to improve network coverage in urban areas. GTL Tower Installation ensures efficient setup, 
              enhancing connectivity and scalability in densely populated regions.
              </Card.Text>
             
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={4} className="d-flex justify-content-center">
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src="card3.jpg" style={{ height: "180px", objectFit: "cover" }} />
            <Card.Body>
              <Card.Title>Mobile Tower Maintenance Service</Card.Title>
              <Card.Text>
              Mobile Tower Maintenance Service involves regular inspections, 
              repairs, and upgrades to ensure optimal performance of mobile towers. 
              GTL Tower Installation provides reliable maintenance, 
              ensuring continuous, high-quality connectivity and minimal downtime.
              </Card.Text>
              
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={4} className="d-flex justify-content-center">
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src="card4.jpg" style={{ height: "180px", objectFit: "cover" }} />
            <Card.Body>
              <Card.Title>Tower Construction and Installation</Card.Title>
              <Card.Text>
              Tower Construction and Installation involves building and setting up
               communication towers for optimal network coverage. GTL Tower Installation ensures safe, efficient construction,
               providing reliable, scalable solutions for mobile and communication services.
              </Card.Text>
             
            </Card.Body>
          </Card>
        </Col>
      
      </Row>
    </Container>
  );
}

export default Services;
